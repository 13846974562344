import React from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import { Layout, Container } from '../components/common'
import SEO from '../components/common/SEO'
import Header from '../components/theme/Header'
import Footer from '../components/theme/Footer'
import TitleSection from '../components/common/TitleSection'
import { Link } from 'gatsby'
import { StaticImage } from "gatsby-plugin-image"

const MainContentHolder = styled.div`
	.pomorie-image {
		width: 100%;
	}

	p {
		font-size: 16px;
		line-height: 24px;
		color: #616156;
		margin-bottom: 20px;
	}

	ul {
		list-style-type: square;
		margin-left: 25px;
	}

	a {
		color: #a19677;
		margin: 0 0 13px;
		padding: 0;
		line-height: 36px;
		font-size: 18px;
		font-weight: 400;

		&:hover {
			color: #d2c970;
		}
	}
`

const HistoryPage = () => (
	<Layout>
		<React.Fragment>
			<SEO title="history" />
			<Header />
			<MainContentHolder className="main-content-holder">
				<TitleSection section="historyText" />
				<StaticImage src="../../static/pomorie.jpg" alt="An image of Pomorie city" />
				<p>
					<FormattedMessage id="historyMainText" />
				</p>
				<ul>
					<li>
						<Link to="/history/history1">
							<FormattedMessage id="history1Header" />
						</Link>
					</li>
					<li>
						<Link to="/history/history2">
							<FormattedMessage id="history2Header" />
						</Link>
					</li>
					<li>
						<Link to="/history/history3">
							<FormattedMessage id="history3Header" />
						</Link>
					</li>
					<li>
						<Link to="/history/history4">
							<FormattedMessage id="history4Header" />
						</Link>
					</li>
					<li>
						<Link to="/history/history5">
							<FormattedMessage id="history5Header" />
						</Link>
					</li>
					<li>
						<Link to="/history/history6">
							<FormattedMessage id="history6Header" />
						</Link>
					</li>
					<li>
						<Link to="/history/history7">
							<FormattedMessage id="history7Header" />
						</Link>
					</li>
					<li>
						<Link to="/history/history8">
							<FormattedMessage id="history8Header" />
						</Link>
					</li>
					<li>
						<Link to="/history/history9">
							<FormattedMessage id="history9Header" />
						</Link>
					</li>
					<li>
						<Link to="/history/history10">
							<FormattedMessage id="history10Header" />
						</Link>
					</li>
				</ul>
			</MainContentHolder>
			<Footer />
		</React.Fragment>
	</Layout>
)

export default HistoryPage
